.loading {
    animation: pulse 1s ease infinite;
    margin-bottom: 5px;
}

@keyframes pulse {
    0% {
        transform: rotate(30deg) scale(0.8);
    }
    50% {
        transform: rotate(-10deg) scale(1);
    }
    100% {
        transform: rotate(30deg) scale(0.8);
    }
}
