.side-menu {
    height: 100%;
    max-width: 400px;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    transition: 0.5s;

    background-repeat: no-repeat;
    background-size: 100% 50%;
    background-position: bottom;

    .flags {
        margin-top: 50px;
    }

    .menu-items {
        list-style: none;
        min-width: 150px;
        max-width: 250px;
        padding: 0;

        .app-store-image-img {
            height: 45px;
        }
    }
    li a {
        font-size: 25px;
        font-weight: 600;
        color: $theme-color-1819-primary;
        display: block;
        transition: 0.3s;
    }
}
