.offer-card:hover {
    transform: scale(1.02);
    transition: 0.2s ease-in-out;
}

.offer-card:active {
    transform: scale(
        0.97
    ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}
.offer-card {
    box-shadow: 0px 3px 14.2px rgba(0, 0, 0, 0.074);
    border-radius: 15px;
    transition: 0.2s ease-in-out;

    .card-img-wrapper-img {
        width: 100%;
        aspect-ratio: 1;
        object-fit: contain;
        height: fit-content;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
    }

    .card-body {
        padding-right: 20px;
        padding-left: 20px;
        position: relative;
        margin-bottom: 2px;
        padding-bottom: 2px;
    }

    .offer-tag {
        position: absolute;
        top: -20px;
        right: -10px;
    }
}

.type-tag {
    &.special-offer {
        background-color: #cf0000;
    }
    &.discount {
        background-color: #faa214;
    }
    &.two-for-one {
        background-color: #0089cf;
    }
    &.introduction {
        background-color: #0eae00;
    }
    z-index: 0;
    position: absolute;
    top: -15px;
    right: 0px;
    font-size: 20px;
    border-radius: 4px;
    padding: 0 3px;
    text-align: center;
    color: #fff;
    font-weight: 700;

    .angle {
        &.special-offer {
            background-color: #cf0000;
        }
        &.discount {
            background-color: #faa214;
        }
        &.two-for-one {
            background-color: #0089cf;
        }
        &.introduction {
            background-color: #0eae00;
        }
        position: absolute;
        bottom: 3.6px;
        width: 20px;
        transform: rotate(45deg);
        height: 76%;
        width: 24px;
        border-radius: 4px;
        z-index: -1;

        &.angle-left {
            left: -9px;
        }

        &.angle-right {
            right: -9px;
        }
    }
}

.offer-tag {
    background-image: url("/tag_bg.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 55px;
    height: 55px;
    color: white;
    font-weight: 900;
    font-size: 16px;
    display: block;
    padding-top: 13px;
    text-align: center;

    span {
        font-weight: 300;
        font-size: 14px;
    }
}

.offer-modal {
    .close-button {
        position: absolute;
        top: 20px;
        left: 30px;
    }

    .modal-body,
    p {
        color: $gray;
    }

    .modal-content {
        border-radius: 40px;
        border: none;
    }

    .modal-body {
        padding-top: 40px;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 40px;
    }

    .modal-img-top {
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        object-fit: scale-down;
        width: 100%;
        height: 0;
        padding-top: 100%;
        background-color: black;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 50%;
    }

    .type-tag {
        box-shadow: 0px 3px 14.2px rgba(0, 0, 0, 0.074);

        &.special-offer {
            background-color: #cf0000;
        }
        &.discount {
            background-color: #faa214;
        }
        &.two-for-one {
            background-color: #0089cf;
        }
        &.introduction {
            background-color: #0eae00;
        }
        z-index: 0;
        position: absolute;
        top: -15px;
        right: 20px;
        font-size: 20px;
        border-radius: 4px;
        padding: 0 3px;
        text-align: center;
        color: #fff;
        font-weight: 700;

        .angle {
            &.special-offer {
                background-color: #cf0000;
            }
            &.discount {
                background-color: #faa214;
            }
            &.two-for-one {
                background-color: #0089cf;
            }
            &.introduction {
                background-color: #0eae00;
            }
            position: absolute;
            bottom: 3.6px;
            width: 20px;
            transform: rotate(45deg);
            height: 76%;
            width: 24px;
            border-radius: 4px;
            z-index: -1;

            &.angle-left {
                left: -9px;
            }

            &.angle-right {
                right: -9px;
            }
        }
    }

    .offer-tag {
        position: absolute;
        top: -28px;
        right: 10px;
    }

    .opening-hours {
        td {
            color: $gray;
        }
    }

    .table {
        margin: 0;
    }

    .active-offer {
        text-align: center;
        padding-top: 20px;
        padding-bottom: 20px;
        color: $darkGray;
        font-weight: 600;
        .countdown {
            font-size: 40px;
            text-align: center;
            font-weight: 900;
            color: $yellow;
        }
    }
}

.active-day-container {
    margin-top: 10px;
    margin-bottom: 15px;
    .active-day {
        border-radius: 5px;
        border: 1px #f1f2f3 solid;
        // @media (prefers-color-scheme: dark) {
        //     border: 1px #333 solid;
        // }
        padding-left: 8px;
        padding-right: 8px;
        box-shadow: 0px 3px 14.2px rgba(0, 0, 0, 0.05);
        color: lightgray;

        &.active {
            box-shadow: 0px 3px 14.2px rgba(0, 0, 0, 0.15);
            background-color: #0eae00;
            color: white;
        }
    }
}

.status-symbol-container {
    margin-bottom: 5px;
    .status-symbol {
        display: inline-block;
        height: 10px;
        width: 10px;
        border-radius: 5px;
        margin-right: 7px;

        &.active {
            background-color: #0eae00;
        }
        &.inactive {
            background-color: #cf0000;
        }
    }
}

.segment {
    margin-bottom: 5px;
    margin-bottom: 40px;
    text-align: center;

    .segment-item {
        display: inline-block;
        user-select: none;
        border-width: 1px;
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
        border-color: #ced4da;
        // @media (prefers-color-scheme: dark) {
        //     border-color: #222222;
        //     color: #fff;
        // }

        border-style: solid;
        padding-top: 5px;
        padding-bottom: 5px;
        cursor: pointer;
        &:hover {
            background-color: #f5f5f5;
            // @media (prefers-color-scheme: dark) {
            //     background-color: #222222;
            // }
        }

        &.selected {
            background-color: #0089cf;
            color: white;
        }

        &.left {
            border-right-width: 1px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        &.center {
            border-right-width: 1px;
            border-left-width: 0px;
        }

        &.right {
            border-left-width: 0px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }
}
