.btn {
    border-radius: 10px;
    &.btn-primary {
        background-color: $theme-color-1819-primary;
        border-color: $theme-color-1819-primary;
    }

    &.btn-disabled {
        background-color: $grayDisabled;
        border-color: $grayDisabled;
    }
}
