.clickable-pill {
    color: white;
    padding: 5px 10px;
    margin: 0px;
    text-align: center;
    font-size: 22px;
    border-radius: 500px;
    margin-bottom: 20px;
    min-width: 225px;
    margin-top: 10px;
    background-color: $theme-color-1819-primary;
    height: 40px;
    font-weight: 600;
    position: relative;

    &:hover {
        background-color: $theme-color-1819-primary;
    }
    &:active {
        background-color: $theme-color-1819-primary;
    }
}
