.company-details {
    .cover-photo {
        width: 100%;
        border-radius: 10px;
        margin-bottom: 20px;
        margin-top: 0px;
    }

    .company-name {
        font-size: 30px;
        font-weight: 600;
    }
    .company-slogan {
        font-size: 20px;
    }

    .job-title {
        font-size: 20px;
        font-style: italic;
    }

    .company-location-group-title {
        margin: 0;
        margin-top: 50px;
        font-weight: 600;
    }

    .company-tag {
        color: white;
        padding: 5px;
        padding-right: 10px;
        padding-left: 10px;
        border-radius: 30px;
        margin-bottom: 10px;
        margin-right: 10px;
    }

    .location-open-indicator {
        height: 10px;
        width: 10px;
        border-radius: 10px;
        vertical-align: "center";
        margin-left: 5px;
        margin-right: 10px;
        &.open {
            background-color: #4caf50;
        }
        &.closed {
            background-color: #cf0000;
        }
    }
}
.large-phone-number {
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 5px;
}

.bordered-container {
    overflow: hidden;
    margin: 0px;
    padding: 0px;
    margin-top: 10px;
    border-radius: 10px;
    border-width: 1px;
    border-style: solid;

    border-color: #e0e0e0;
    border-color: #00000022;

    // @media (prefers-color-scheme: dark) {
    //     border-color: #222;
    // }
    &.bordered-container-summary {
        padding-top: 15px;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
    }
    &.bordered-container-small-sections {
        padding-top: 8px;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 5px;
        min-height: 51px;
    }

    &.bordered-container-information {
        padding: 10px 30px;
    }
    &.bordered-container-search-result {
        padding: 10px;
    }
    &.bordered-container-search-result:hover {
        background-color: #f9f9f9;
        // @media (prefers-color-scheme: dark) {
        //     background-color: #191919;
        // }
        transition: 0.1s ease-in-out;
    }
}

.marker-container-small {
    background-color: white;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    display: flex;
}

.marker-container {
    background-color: green;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    display: flex;
}
.marker {
    background-color: white;
    background-size: 100%;
    background-repeat: no-repeat;
    border-radius: 50%;
    align-items: center;
    height: 30px;
    width: 30px;
    margin: auto;
    background-position: center;
    transform: translateZ(0);
}

.marker-small {
    background-color: $theme-color-1819-primary;

    background-size: 100%;
    background-repeat: no-repeat;
    border-radius: 50%;
    align-items: center;
    height: 13px;
    width: 13px;
    margin: auto;
    background-position: center;
}

.mapboxgl-canvas-container {
    border-radius: 10px;
}
.map-container {
    border-radius: 10px;

    mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
}

.leaf-side-container {
    background-color: #00936915;
    padding: 10px 30px;
    border-width: 1px;
    max-width: 275px;
    border-style: solid;
    border-color: #008854;
    border-radius: 10px;
    margin-bottom: 20px;
    text-align: center;
    h3,
    p,
    span {
        color: #008854;
    }
}

.leaf-tooltip {
    font-size: 25px;
}

.plus-tooltip {
    max-width: 300px;
}

.ReactModal__Overlay {
    opacity: 0;
    transform: translateY(10px);
    transition: all 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
    transform: translateY(0px);
}

.ReactModal__Overlay--before-close {
    opacity: 0;
    transform: translateY(10px);
}

.audlindatorg-segment-item {
    border: 1px solid #1c936a;
    border-radius: 10px;
    padding: 5px 15px;
    font-weight: bold;
    margin-right: 10px;
    color: #1c936a;
    cursor: pointer;

    &.selected {
        background-color: #1c936a;
        color: white;
    }
}

.branch-container {
    &.highlighted {
        background-color: lightgray;
        // @media (prefers-color-scheme: dark) {
        //     background-color: #444;
        // }
    }
}
