.footer {
    .footer-container {
        border-top: 3px solid $theme-color-1819-primary;

        background-color: #fff;
        // @media (prefers-color-scheme: dark) {
        //     background-color: #000;
        // }

        width: "100%";
        position: relative;
    }
    .logo-container {
        position: absolute;
        right: 0px;
        width: 100px;
        top: -40px;
    }

    .footer-content {
        color: #fff;

        .social {
            margin-bottom: 50px;

            .text {
                color: #7ed3ff;
                font-size: 20px;
                margin-bottom: 20px;
            }
        }

        .footer-navigation {
            padding-top: 50px;
            ul {
                list-style: none;
                padding-left: 0px;
                li > a {
                    color: #595959;
                    // @media (prefers-color-scheme: dark) {
                    //     color: #cccccc;
                    // }

                    font-size: 18px;
                    &:hover {
                        color: $theme-color-1819-primary;
                    }
                }
            }
            .row-item {
                margin-bottom: 20px;
            }
        }
    }
    .social-media {
        height: 30px;
    }
    .bottom-line {
        border-top: 1px solid #fff;
    }

    .bottom-text {
        text-align: center;
        padding-top: 15px;
        padding-bottom: 15px;
    }
}
