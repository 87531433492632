html {
    background-color: transparent;
}

body {
    background-color: #fff;
    color: #000;
    font-family: "Source Sans Pro", sans-serif;
}

// Base
@import "base/base.scss";

// Pages
@import "pages/home_page";
@import "pages/services";
@import "pages/company";
@import "pages/login";
@import "pages/map";
@import "pages/synileiki";
@import "pages/simsvorun";

// Mitt
@import "pages/mitt/simsvorun.scss";
@import "pages/mitt/skraning.scss";
@import "pages/mitt/svar.scss";
@import "pages/mitt/simkerfi/simsvorun.scss";
@import "pages/mitt/thjodskra.scss";
@import "pages/mitt/innra_layout.scss";

// Components
@import "components/clickable_pill";
@import "components/page";
@import "components/offer";
@import "components/side-menu.scss";
@import "components/side_bar.scss";
@import "components/company_info.scss";
@import "components/footer";
@import "components/close_button.scss";
@import "components/reset_verify.scss";
@import "components/search_bar.scss";
@import "components/draggable_modal.scss";
@import "components/video_preview.scss";

.rti--container {
    --rti-border: #ccc;
    --rti-main: #8bb7fb !important;
    --rti-radius: 0.375rem !important;
    --rti-s: 0.5rem !important;
    --rti-tag: #25687d99 !important;
    --rti-tag-remove: #fff !important;
    min-height: 46px;

    .rti--tag {
        border-radius: 100px;
        padding: 3px 10px 3px 10px;
        background-color: #25687dcc;
        span {
            color: #fff;
        }
        button {
            color: #ffffffaa;
        }
    }
    .rti--input {
        flex: 1;
        min-width: 150px;
    }
}

.form-control:focus {
    border-color: rgb(23, 77, 111);
    box-shadow: 0px 0px 0px 4px rgba(23, 77, 111, 0.3);
}
