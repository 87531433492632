.text-blue {
    color: $theme-color-1819-primary;
    // @media (prefers-color-scheme: dark) {
    //     color: $theme-color;
    // }
}

.text-white {
    color: $white;
}

.text-center {
    text-align: center;
}

.text-gray {
    color: $gray;
}
.text-dark-gray {
    color: $darkGray;
}

.fw-extra {
    font-weight: 900;
}

.fw-bold {
    font-weight: 600;
}

.fw-normal {
    font-weight: normal;
}

a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
    color: inherit;
}

.underline-on-hover:hover {
    text-decoration: underline;
}
