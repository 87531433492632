.page {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;

    .page-header {
        background-color: $theme-color-1819-primary;
        //position: relative;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 100;
        border-bottom: 1px solid #00000022;
        box-shadow: 0px 4px 20px 0px #00000022;
        .logo-container {
            height: 65px;
            width: 50px;
            padding-top: 15px;
            padding-bottom: 9px;
        }
        .header-list {
            margin-left: 0px;
        }
        .header-accessories {
            @media screen and (max-width: 450px) {
                display: none !important;
            }
        }
    }

    .page-content {
        flex: 1;

        padding-top: 65px;
        .page-title {
            width: 100%;
        }

        .page-body {
            padding-top: 15px;
            padding-bottom: 80px;
        }
    }

    .menu-button {
        border: none;
    }

    .page-section {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .page-list-item-a {
        text-decoration: none;
    }

    .page-list-item {
        color: white;
        display: inline-flex;
        height: 38px;
        padding: 0px 12px;
        font-size: 20px;
        align-content: center;
        align-items: center;
        width: 100%;
        align-self: center;
        text-align: center;
        vertical-align: middle;
        border-radius: 2px;

        &:hover {
            background-color: white;
            color: $theme-color-1819-primary;
        }
    }
}
