.thjodskra-table thead tr {
    text-align: left;
}

.thjodskra-table td {
    padding: 12px 12px 12px 5px;
}

.thjodskra-table tbody tr {
    border-bottom: 1px solid #e0e0e0;
}

.thjodskra-table tbody tr:nth-last-of-type(even) {
    background-color: #eff2f5;
}

.thjodskra-info-section {
    padding: 0px;
}

.thjodskra-search-bar {
    border: none;
    margin: 0.5rem;
    padding-left: 1rem;
    height: 2rem;
    border-radius: 200px;
    outline: 1px solid #ccc;
}

.thjodskra-btn {
    border: none;
    border-radius: 200px;
    background-color: #174d6f;
    color: #fff;
    width: 5rem;
    height: 2rem;
}

.social-security-number {
    color: #cf0000;
}
