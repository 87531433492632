@mixin padding-helper($amount) {
    .px-#{$amount} {
        padding-left: #{$amount}px !important;
        padding-right: #{$amount}px !important;
    }
}

@include padding-helper(0);
@include padding-helper(10);
@include padding-helper(15);
@include padding-helper(20);

@mixin margin-helper($amount) {
    .mb-#{$amount} {
        margin-bottom: #{$amount}px !important;
    }
}

@include margin-helper(0);
@include margin-helper(10);
@include margin-helper(15);
@include margin-helper(20);
@include margin-helper(30);
@include margin-helper(40);
