.registration-preview {
    flex: 1;
    padding: 0;
    margin: 0;
    border-radius: 5px;

    @media screen and (max-width: 2500px) {
        width: 200%;
        transform: scale(0.5) translateX(-50%) translateY(-50%);
    }
}

.social-media-container {
    margin-bottom: 5px;
    margin-top: 5px;
    justify-content: center;
    align-items: center;

    img {
        height: 25px;
        width: 25px;
    }
}

.nav.nav-pills.nav-justified {
    border: 1px solid #174d6f40 !important;
}
.nav-link {
    color: $theme-color-1819-primary !important;
    border-radius: 100px !important;
    font-weight: 600;
    font-size: 1.1rem;
    &.active {
        background-color: #174d6f33 !important;
        color: $theme-color-1819-primary !important;
    }
}

.ReactTags__tags {
    position: relative;
}

.ReactTags__clearAll {
    cursor: pointer;
    padding: 10px;
    margin: 10px;
    background: #f88d8d;
    color: #fff;
    border: none;
}

.ReactTags__tagInput {
    display: inline-block;
}
.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
    font-size: 12px;
    border: 1px solid #bbb;
    min-width: 150px;
    border-radius: 30px;
    border-radius: 30px;
    margin: 0 1px;
    padding: 0 5px;
}

.ReactTags__editInput {
    border-radius: 1px;
}

.ReactTags__editTagInput {
    display: inline-flex;
}

/* Styles for selected tags */
.ReactTags__selected span.ReactTags__tag {
    border: none;
    background: #25687d99;
    color: white;
    font-size: 0.8em;
    display: inline-block;
    padding: 0px 7px;
    margin: 0 1px;
    border-radius: 30px;
    font-weight: 600;
}
.ReactTags__selected a.ReactTags__remove {
    color: #aaa;
    margin-left: 5px;
    cursor: pointer;
}

.ReactTags__remove {
    border: none;
    cursor: pointer;
    background: none;
    padding: 0px;
    padding-left: 3px;
    color: white;
}

.registration-basic-info-section {
    label {
        font-weight: 600;
    }
    input {
        margin-bottom: 10px;
    }

    // Media query max width
    @media screen and (max-width: 950px) {
        .basic-info-container {
            flex-direction: column;
        }
    }
}

.form-check-input:checked {
    background-color: $theme-color-1819-primary;
    border-color: $theme-color-1819-primary;

    &.phone-number-banned {
        background-color: #cf0000;
        border-color: #cf0000;
    }
}
