.login-container {
    border-radius: 20px;
    text-align: center;
    max-width: 500px;
}

.login-field {
    padding-right: 25px;
    padding-left: 25px;
    border-radius: 30px;
    border-style: solid;
    border-width: 1px;
    font-size: 20px;
    width: 100%;
    height: 50px;
}

.login-button {
    width: 100%;
    border-style: none;
    border-radius: 30px;
    background-color: #003a58;
    font-size: 25px;
    color: white;
    height: 50;
}
