.break {
    flex-basis: 100%;
    height: 0;
}

.success-icon {
    width: 50;
    height: 50;
    display: inline;
    margin-bottom: 50;
}
.success-container {
    display: flex;
    flex-wrap: wrap;
}
