$imageSize: 145px;

.service-header {
    img {
        float: left;
        border-radius: 20px;
    }
    h2 {
        float: left;
        margin-left: 35px;
        color: $theme-color-1819-primary;
        font-weight: bold;
        font-size: 2.5em;
    }
    @media screen and (max-width: 575px) {
        flex-direction: column;
        text-align: center;
        h2 {
            margin-top: 10px;
            margin-left: 0px;
            font-size: 2em;
        }

        img {
            width: 100px;
            height: 100px;
        }
    }
}

.service-item {
    margin-bottom: 25px;
    padding: 15px;
    border-radius: 20px;
    .service-image {
        float: left;
        width: $imageSize;
        border-radius: 20px;
    }
    .service-item-description-container {
        margin-left: $imageSize + 20px;
    }
}
.simsvorun-try {
    align-self: center;
    text-align: center;
    color: white;
    font-size: 1.5em;
    font-weight: bold;
    border-radius: 10px;
    background-color: $theme-color-1819-primary;
    padding: 2px 20px;
    margin-top: 10px;
    margin-bottom: 20px;
    div {
        color: white;
    }
}

.simsvorun-header-info {
    background-color: $theme-color-1819-primary;
    border-radius: 15px;
    padding: 15px;
    align-items: center;
    text-align: center;
    @media screen and (max-width: 575px) {
        flex-direction: column;
        .info-icon-start {
            margin-bottom: 10px;
        }

        .info-icon-end {
            margin-top: 10px;
        }
    }

    margin-bottom: 10px;
    p {
        color: white;
        font-size: 1.5em;
        font-weight: 600;
        line-height: 1.2em;
        margin: 0px;
        padding: 0px 20px;
        vertical-align: center;

        @media screen and (max-width: 575px) {
            width: 100%;
        }
    }
    .simsvorun-header-info-separator {
        background-color: white;
        width: 2px;
        height: 35px;
        @media screen and (max-width: 575px) {
            justify-content: center;
            height: 1px;
            width: 100%;
            margin: 10px 10px;
        }
    }
    .simsvorun-header-info-clock {
        color: white;
        width: 65px;
        align-self: center;
    }
}

.simsvorun-video-container {
    position: relative;
    width: 100%;
    aspect-ratio: 16/9;
    border-radius: 10px;
    overflow: hidden;
    background-color: $theme-color-1819-primary;
    justify-content: center;
    cursor: pointer;

    h4 {
        color: white;
        font-weight: bold;
        text-align: center;
        font-size: 2.5em;
        margin-top: 25px;
        align-self: center;
    }
    h5 {
        color: white;
        font-size: 0.9em;
        font-weight: 600;
        text-align: center;

        align-self: center;
        position: absolute;
        bottom: 100px;
        max-width: 160px;
        left: 0px;
        right: 0px;
    }
}

.simsvorun-video-section {
    padding: 10px !important;
    & h3 {
        margin-top: 20px;
        margin-bottom: 20px;
        font-weight: bold;
        color: $theme-color-1819-primary;
        text-align: center;
    }
}

.simsvorun-reviews-section {
    display: flex;
    flex-direction: column;
    padding: 10px 50px !important;
    & h3 {
        font-weight: bold;
        color: $theme-color-1819-primary;
        text-align: center;
    }
    .simsvorun-review {
        text-align: center;
        font-style: italic;
    }

    .simsvorun-reviewer {
        text-align: center;
        color: $theme-color-1819-primary;
        font-weight: 700;
    }

    .carousel-container {
        display: flex;
        width: 100%;
    }
    .carousel-chevron-next {
        position: block;
        z-index: 2;
        top: 0;
        bottom: 0;
        right: 0px;
    }
    button {
        padding: 15px;
        border: none;
        font-size: 2em;
        background: none;
    }

    .carousel-chevron-prev {
        position: block;
        z-index: 2;
        top: 0;
        bottom: 0;
        left: 0;
    }

    & img {
        max-height: 100px;
        max-width: 150px;
        width: auto;
        height: auto;

        align-self: center;
    }
}

.simsvorun-qna-section {
    display: flex;
    flex-direction: column;
    padding: 10px 10px !important;
    & h3 {
        font-weight: bold;
        color: $theme-color-1819-primary;
        text-align: center;
        margin-bottom: 0px;
        font-size: 2em;
    }
    & h4 {
        font-weight: bold;
        color: $theme-color-1819-primary;
        text-align: center;
        font-size: 1.2em;
        margin-bottom: 10px;
    }
}

.simsvorun-packages-section {
    display: flex;
    flex-direction: column;
    padding: 10px 10px !important;
    & h3 {
        font-weight: bold;
        color: $theme-color-1819-primary;
        text-align: center;
        margin-bottom: 0px;
        font-size: 2em;
    }
    & h4 {
        font-weight: bold;
        color: $theme-color-1819-primary;
        text-align: center;
        font-size: 1.2em;
        margin-bottom: 10px;
    }
}

.simsvorun-compare-section {
    display: flex;
    flex-direction: column;
    padding: 10px 10px !important;
    & h3 {
        font-weight: bold;
        color: $theme-color-1819-primary;
        text-align: center;
        margin-bottom: 0px;
        font-size: 2em;
    }
    & h4 {
        font-weight: bold;
        color: $theme-color-1819-primary;
        text-align: center;
        font-size: 1.2em;
        margin-bottom: 10px;
    }
}
.simsvorun-packages-container {
    margin: 10px 0px;
    justify-content: space-around;
    gap: 10px;
    @media screen and (max-width: 990px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.simsvorun-package-background {
    border-radius: 10px;
    width: 200px;
    min-width: 200px;
    color: white;
    // cursor: pointer;
    .simsvorun-package-container {
        flex: 1;
        background-color: $theme-color-1819-primary;
        margin: 3.5%;
        border: 1px solid #a7b3bb;
        box-shadow: 0px 0px 5px #000000aa;
        border-radius: 10px;
        align-items: center;
        color: inherit;
        div {
            color: white;
        }
        .call-count {
            color: inherit;
            font-size: 4em;
            line-height: 1em;
            font-weight: 900;
            margin: 0px;
            padding: 0px;
            margin-bottom: 0px;
        }
        .package-description {
            color: inherit;
            margin-top: 5px;
            font-weight: 600;
            line-height: 1em;
            padding: 0px 12px;
            border-radius: 10px;
            text-align: center;
        }
        .package-company-size {
            color: inherit;
            margin-top: 5px;
            line-height: 1em;
            padding: 0px 12px;
            border-radius: 10px;
            text-align: center;
        }
        .package-price {
            margin: 10px 0px;
            line-height: 1.2em;
            background-color: white;
            color: $theme-color-1819-primary;
            font-size: 1.5em;
            font-weight: 900;
            padding: 0px 15px;
            border-radius: 7px;
            box-shadow: 0px 0px 7px #00000022;
            span {
                color: inherit;
                background-color: inherit;
                font-size: 0.8em;
                margin-left: 2px;
                font-weight: 800;
            }
        }
    }
    @media screen and (max-width: 575px) {
        margin-bottom: 10px;
    }
    // &:hover .simsvorun-package-container {
    //     background-color: white;
    //     color: $theme-color-1819-primary;
    // }

    // &:hover .package-price {
    //     background-color: $theme-color-1819-primary;
    //     color: white;
    // }
}
