.video-preview {
    margin-bottom: 30px;
    .video-preview-container {
        position: relative;
        cursor: pointer;
        aspect-ratio: 16/9;
    }
    .video-preview-picture {
        width: 100%;
        height: 100%;
    }
    .video-preview-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-color: #000;
        border-radius: 10px;
        filter: brightness(60%);
    }
    .video-play-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: lightgray;
        opacity: 0.9;
        z-index: 10;
    }
    .video-info-container {
        margin-bottom: 10px;
        margin-top: 10px;
        margin-left: 5px;
        font-size: 18px;
        font-weight: 600;
    }
    .company-logo {
        height: 30px;
        width: 30px;
        object-fit: contain;
        overflow: hidden;
        margin-right: 10px;
    }
    h3 {
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 0em;
    }
    h4 {
        font-size: 1rem;
        margin-bottom: 0rem;
        color: #555;
    }
}
